.calculator-image {
  background-image: url('../public/calculator_truck.jpg');
  background-size: cover;
  height: 100%;
}

#calculator {
  overflow-y: auto;
}

.calculator-container {
  margin-top: 5vh;
  padding: 25px 25px 50px 25px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
}

.input-label {
  font-weight: 300 !important;
  color: #333B3F;
}

.calculator-label {
  margin: 3px auto 18px -7px;
  font-weight: 275;
  font-style: normal !important;
  font-size: 0.8rem;
  color: #333B3F;
}

.center-calculator-navbar {
  max-width: 600px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 20px;
}

.calculator-button {
  margin-top: 1.5rem !important;
  background: #333746 !important;
  width: 100% !important;
  color: #FFFFFF !important;
  line-height: 2.5rem !important;
  border-radius: 0px !important;
  font-weight: 600 !important;
}

.order-button {
  background: #FFFFFF !important;
  color: #333746 !important;
  line-height: 1.5rem !important;
  border-radius: 0px !important;
  font-weight: 600 !important;
  width: 100% !important;
  border: 1px solid #333746 !important;
  margin-top: 10px;
}

.calculator-button:focus {
  outline: none;
  box-shadow: none;
}

.input-div {
  padding-left: 3px !important;
  padding-right: 3px !important;
  padding-top: 4px;
}

.no-results {
  color: #FFFFFF;
  font-weight: 800;
  text-align: center;
}

.truck-icon {
  padding-bottom: 8px;
}

.calculator-header {
  margin-left: -7px;
  padding-bottom: 15px;
  font-weight: 800 !important;
}

.price-card {
  padding: 20px !important;
  width: 320px !important;
  background-color: #FFFFFF;
  margin: 10px !important;
}

.no-price-card {
  padding: 20px !important;
  width: 400px !important;
  background-color: #798b9b;
  margin: 10px !important;
  height: 350px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.prices-container {
  margin-left: auto !important;
  margin-right: auto !important;
  justify-content: center !important;
  width: 100% !important;
}

.price-card-muted {
  font-weight: 275 !important;
  font-size: 0.8rem;
  color: #333B3F;
}

.back-button {
  color: #FFFFFF !important;
  width: 320px !important;
  margin: 10px !important;
  text-align: start !important;
  cursor: pointer;
  padding-left: 0 !important;
  text-decoration: none !important;
}
@media (min-width: 711px) {
  .back-button {
    width: 660px !important;
  }
}

.price-value {
  font-weight: 600 !important;
}

.total-price {
  font-weight: 800 !important;
  font-size: 2.4rem;
}

.service-name {
  font-weight: 600 !important;
}

.estimated-dates {
  font-size: 0.9rem !important;
  color: #333B3F;
  height: 1.25rem;
}

.loading-spinner {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  width: 5rem; 
  height: 5rem; 
  border: 0.75rem solid white; 
  border-right-color: transparent;
}

.pallets-modal-button:hover {
  cursor: pointer;
}