.vertical-line {
    height: 16px;
    width: 50%;
    border-right: 1px solid gray;
}

nav {
    background: #FFFFFF;
}

.my-navbar {
    padding-top: 0px;
    padding-bottom: 15px;
  }
  
.logo {
    padding: 10px;
    background-color: #333746;
}

.cargo-header {
    margin: 3px;
    color: #FFFFFF;
    font-weight: 800;
    line-height: 0.9rem !important;
    font-size: 1.2rem;
}

.center-navbar {
    max-width: 400px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 20px;
}

.carrier-logo {
    max-height:75px;
    max-width:150px;
    height:auto;
    width:auto;
    padding-left: 12px;
    padding-right: 10px;
}

.navbar-end {
    font-size: 0.9rem !important;
    float: right;
    position: relative;
    margin-right: 10px;
    top: -60px;
    display: flex !important;
    justify-content: space-between !important;
}

.by-word {
    font-size: 0.6rem !important;
    margin-top: 0.5rem !important;
}

.log-out-button {
    background: #FFFFFF !important;
    color: #333746 !important;
    border-radius: 0px !important;
    margin-left: 20px;
    border: 1px solid #333746;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 600;
}

.bi-person {
    padding: 0.5rem 0.75rem;
}