.landing-image {
  background-image: url('../public/landing_page.jpg');
  background-size: cover;
}

.log-in-container {
  margin-top: 25vh;
  padding: 25px 25px 50px 25px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  background: #FFFFFF;
}

.log-in-button {
  background: #333746 !important;
  width: 100% !important;
  color: #FFFFFF !important;
  line-height: 2.5rem !important;
  border-radius: 0px !important;
}

.credentials-info {
  font-weight: 300 !important;
  font-size: 0.9rem !important;
  padding-top: 0.6rem !important;
}