@media (min-width: 768px) {
  .calculator-bg {
    background: #FFFFFF;
  }
  .landing-bg {
    background: #FFFFFF;
  }
  .back-button, #mobile-navbar-icon, #nav-results {
    display: none !important;
  }
  #calculator, #results {
    display: block !important;
  }
  .calculator-image {
    overflow-y: auto;
  }
  .prices-container {
    padding-top: 20vh !important;
  }
  select {
    padding-left: 5px !important;
  }
}

@media (max-width: 767px) {
  .calculator-bg {
    background-image: url('../public/calculator_truck.jpg');
    background-size: cover;
    overflow-y: auto;
  }
  .calculator-image {
    background-image: none;
  }
  .landing-bg {
    background-image: url('../public/landing_page.jpg');
    background-size: cover;
  }
  #results, .landing-image, .log-out-button {
    display: none;
  }
  .prices-container {
    padding-top: 5vh !important;
  }
}

@media (max-width: 1079px) {
  .user-info {
    display: none;
  }
}privacy

h1, h2, h3, h4, h5, h6, text, button, label, b, button, div, input, select, option {
  font-family: 'Kanit', sans-serif !important;
  font-weight: 500
}

b {
  font-weight: 1000 !important;
}

.max-height {
  height: 100vh;
  border: 3px;
  display: flex;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.padding-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.default-input {
  border: 1px solid #000000 !important; 
  box-sizing: border-box !important;
  border-radius: 0px !important;
}

.default-input:focus {
  box-shadow: 0 0 0 0.10rem gray !important;
}

.default-input::placeholder {
  font-size: 0.8rem !important;
  font-style: normal !important;
}

label {
  opacity: 0.5 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-300 {
  font-weight: 300 !important;
}